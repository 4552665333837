import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  VStack,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Image,
  HStack,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useFormik } from "formik";
import { useEffect, useContext, useState } from "react";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import { AppConfigContext } from "../context";
import { useBalance } from "../hooks/useBalance";
import { ArrowDownIcon, InfoIcon } from "@chakra-ui/icons";
import { useMintTo } from "../hooks/useMintTo";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useKyrosApy } from "../hooks/useKyrosApy";
import { CTA, NcnTable, UncappedTvl } from "../components";
import "../styles/MintBox.css";
import { solAmountFormatter } from "../helpers/formatter";
import { useTotalSupply } from "../hooks/useTotalSupply";
import { useJtoPrice } from "../hooks/useJtoPrice";
import { useNcns } from "../hooks/useNcns";
import { useExchangeRate } from "../hooks/useExchangeRate";

export const RestakeJtoPage = () => {
  const { apy: kyrosApy } = useKyrosApy();
  const { price } = useJtoPrice();
  const { publicKey } = useWallet();
  const { jtoMintAddress, jtoVrtMintAddress, jtoVaultAddress } = useContext(AppConfigContext);

  const [displayedBalance, setDisplayedBalance] = useState<string>("0 JTO");
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const { balance: jtoBalance, fetchBalance: fetchJtoBalance } = useBalance(new PublicKey(jtoMintAddress));
  const { totalSupply, isLoading: isTotalSupplyLoading, error: totalSupplyError, fetchTotalSupply } = useTotalSupply();
  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();
  const { mintTo, error: mintToError } = useMintTo();
  const { jtoNcns: ncns, fetchNcns } = useNcns();

  const estimatedKyJtoRate = 1
  const { rate: kyJtoRate, fetchRate: fetchKyJtoRate } = useExchangeRate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numberValue = Number(inputValue)
    const outputValue = numberValue * (kyJtoRate ?? estimatedKyJtoRate)

    formik.setValues({
      amount: inputValue,
      receivedAmount: solAmountFormatter.format(outputValue)
    });
  };

  const handleMaxClick = () => {
    const maxBalance = jtoBalance?.toString() || "0"
    const maxOutput = Number(jtoBalance ?? "0") * (kyJtoRate ?? estimatedKyJtoRate)
    
    formik.setValues({
      amount: solAmountFormatter.format(Number(maxBalance)).replace(/,/g, ""),
      receivedAmount: solAmountFormatter.format(Number(maxOutput)).replace(/,/g, "")
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      receivedAmount: "",
    },
    onSubmit: async (values) => {
      try {
        const signature = await mintTo(jtoVrtMintAddress, jtoMintAddress, jtoVaultAddress, values.amount, undefined);
        if (signature) {
          successToast("Your mint was successful!");
          reload();
        }
      } catch (err) {
        errorToast(`${err}`);
      }
    },
  });

  useEffect(() => {
    if (mintToError) {
      errorToast(mintToError);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintToError]);

  const reload = () => {
    formik.resetForm();
    fetchJtoBalance(publicKey);
    fetchTotalSupply(jtoVrtMintAddress);
    fetchNcns();
    fetchKyJtoRate(jtoVaultAddress)
  };

  useEffect(() => {
    setFormDisabled((Number(formik.values.amount) > Number(jtoBalance?.toString() || Number("0"))) || formik.values.amount === "0" || formik.values.amount === "")
  }, [formik.values.amount, jtoBalance]);

  useEffect(() => {
    fetchJtoBalance(publicKey);
    fetchTotalSupply(jtoVrtMintAddress);
    fetchNcns();
    fetchKyJtoRate(jtoVaultAddress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    setDisplayedBalance(solAmountFormatter.format(Number(jtoBalance?.toString())) + " JTO");
  }, [jtoBalance]);

  const { setVisible } = useWalletModal();
  const { connecting } = useWallet();

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW="520px"
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Get kyJTO
              </Text>
              {kyrosApy !== null && (
                <Badge
                  colorScheme="green"
                  borderRadius="full"
                  px={4}
                  py={1}
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  height="auto"
                >
                  ≈ {(kyrosApy?.estimatedJto * 100).toFixed(2)}% APY
                  <Tooltip label={
                    <>
                      <VStack spacing={2}>
                        <HStack justifyContent="space-between" width="100%">
                          <Text fontSize="xs" textAlign="left">TipRouter</Text>
                          <Text fontSize="xs" textAlign="right" fontWeight="bold">{((kyrosApy?.estimatedJto - kyrosApy.jto) * 100).toFixed(2)}% APY</Text>
                        </HStack>
                      </VStack>
                      <Divider mt={2} mb={2} />
                      <Text fontSize="xs" textAlign="left">The APY is an estimate based on the current JTO restaked TVL and the tips received yesterday by the TipRouter NCN.</Text>
                    </>
                  } width="500px">
                    <InfoIcon ml={1} />
                  </Tooltip>
                </Badge>
              )}
            </Flex>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              Earn yield on your Jito (JTO) tokens by restaking them on Jito TipRouter NCN.
            </Text>

            <UncappedTvl currency="JTO" price={price} totalSupply={totalSupply} isTotalSupplyLoading={isTotalSupplyLoading} totalSupplyError={totalSupplyError} />

            <Flex justifyContent="space-between" alignItems="stretch" gap={4}>
              <CTA
                destination="https://app.kamino.finance/liquidity/CMgoBugT4yFa18gL9aMev4CRpZyFDrv1AkfvgHPadWBN"
                destinationTracking="kamino"
                image="/kyjto.svg"
                title={"Earn more on your kyJTO!"}
                description="Provide kyJTO/JTO LP on Kamino, trade yield on RateX and Sandglass and earn bonus points!"
              />
            </Flex>

            <Box className="mint-box">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={4}
              >
                <Text fontSize="md" fontWeight="semibold" color="white">
                  (RE)STAKE
                </Text>
                {publicKey && (
                  <HStack spacing={2} alignItems="center">
                    <Image src="/wallet-icon.svg" boxSize="16px" />
                    <Text fontSize="sm" fontWeight="light" color="white">
                      {displayedBalance}
                    </Text>
                    <Button
                      size="sm"
                      onClick={handleMaxClick}
                      variant="ghost"
                      color="whiteAlpha.800"
                      _hover={{ bg: "whiteAlpha.200" }}
                      backgroundColor="whiteAlpha.300"
                      fontWeight="normal"
                      paddingX={2}
                    >
                      MAX
                    </Button>
                  </HStack>
                )}
              </Flex>

              <form onSubmit={formik.handleSubmit}>
                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >

                  <InputLeftAddon
                    p={0}
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "xl",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      _hover: { color: "white.300" },
                      width: "150px",        // Fixed width
                    }}
                  >
                    <Box display="flex" alignItems="center" pl={3}>
                      <Image
                        src="/jto.svg"
                        boxSize="30px"
                        ml={2}
                        borderRadius="full"
                        border="2px solid #222F44"
                      />
                      <Text fontWeight="bold" ml={2} color="white">JTO</Text>
                    </Box>
                  </InputLeftAddon>

                  <Input
                    id="amount"
                    placeholder="0.0"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    color="white"
                    sx={{
                      width: "100%",
                      height: "60px",
                      fontSize: "md",
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",
                      _placeholder: { color: "#595959" },
                    }}
                  />

                </InputGroup>

                <Flex justifyContent="center" alignItems="center">
                  <ArrowDownIcon color="grey" boxSize={6} />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    TO RECEIVE
                  </Text>
                  <Text fontSize="xs" fontWeight="light" color="#9A9998">
                    0% Slippage
                  </Text>
                </Flex>

                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >
                  <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/kyjto.svg"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">kyJTO</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  />
                  <Input
                    id="receive"
                    placeholder="0.0"
                    value={formik.values.receivedAmount}
                    readOnly
                    color="white"
                    sx={{
                      width: "100%",        // Makes the input take up full width within InputGroup
                      height: "60px",       // Set height to match addon
                      fontSize: "md",       // Set font size to match addon
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",     // Aligns the text to the right
                      _placeholder: { color: "595959" },
                    }}
                  />
                </InputGroup>

                {!publicKey ? (
                  <Flex justifyContent="center" width="100%">
                    <Button
                      width="100%"
                      className="mint-box-button"
                      isDisabled={connecting}
                      onClick={() => setVisible(true)}
                    >
                      {connecting ? "Connecting..." : "Connect Wallet"}
                    </Button>
                  </Flex>
                ) : (
                  <Flex justifyContent="center" width="100%">
                    <Button width="100%" className="mint-box-button" isDisabled={formDisabled} isLoading={formik.isSubmitting} type="submit">
                      Convert to kyJTO
                    </Button>
                  </Flex>
                )}
              </form>

              <Flex className="exchange-info" mt={4}>
                <Text>Exchange rate:</Text>
                <Text ml={2}>1 JTO = {kyJtoRate?.toFixed(5) ?? 1} kyJTO</Text>
              </Flex>
            </Box>

            <NcnTable ncns={ncns ?? []} />

          </VStack >
        </Container >
      </Flex >
    </>
  );
};
