import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { PublicKey } from '@solana/web3.js';
import { Referral } from '../rest/kairos/types';

export const useReferrals = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [referralProfile, setReferralProfile] = useState<Referral | null>(null);
    const [challenge, setChallenge] = useState<string | null>(null);
    const [challengeVerified, setChallengeVerified] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchReferrals = async (wallet: PublicKey | null) => {
        if (!wallet) {
            setReferralProfile(null);
            setIsLoading(false);
            setError("Wallet not connected");
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/referral?wallet=${wallet.toBase58()}`);
            const data = await response.json();
            if (data) {
                if ('error' in data) {
                    setError(data.error);
                } else {
                    const referralProfileData: Referral = data;
                    setReferralProfile(referralProfileData);
                }
            }
        } catch (err) {
            console.error("Error fetching Referrals:", err);
            setReferralProfile(null);
            setError("Error fetching Referrals");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChallenge = async (wallet: PublicKey | null, referralCode: string) => {
        if (!wallet) {
            setChallenge(null);
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/referral/challenge?wallet=${wallet.toBase58()}&referrer=${referralCode}`);
            const data = await response.json();
            if (data) {
                if ('error' in data) {
                    setError(data.error);
                } else {
                    setChallenge(data.challenge);
                }
            }
        } catch (err) {
            console.error("Error fetching Challenge:", err);
            setChallenge(null);
            setError("Error fetching Challenge");
        } finally {
            setIsLoading(false);
        }
    }

    const verifyChallenge = async (wallet: PublicKey | null, signature: string, isLedger: boolean) => {
        if (!wallet) {
            setChallengeVerified(false);
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${apiUrl}/kyros/referral/challenge?wallet=${wallet.toBase58()}&signature=${signature}&ledger=${isLedger ? "true" : "false"}`, { method: "POST" });
            const data = await response.json();
            if (data) {
                if ('error' in data) {
                    setError(data.error);
                } else {
                    setChallengeVerified(data.verified);
                }
            }
        } catch (err) {
            console.error("Error verifying Challenge:", err);
            setChallengeVerified(false);
            setError("Error verifying Challenge");
        } finally {
            setIsLoading(false);
        }
    }

    return { referralProfile, challenge, challengeVerified, isLoading, error, fetchReferrals, fetchChallenge, verifyChallenge };
};