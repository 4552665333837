import { Box, Flex, Table, Th, Tr, Text, TableContainer, Tbody, Td, Thead, Spinner, HStack, Image } from "@chakra-ui/react";
import { NodeOperator } from "../../hooks/useNodeOperators";

interface DashboardTableProps {
    nodeOperators: NodeOperator[];
    isLoading: boolean;
}

export const DashboardTable = ({ nodeOperators, isLoading }: DashboardTableProps) => {
    return (
        <>
            <Box bg="#18181b" p={8} className="mint-box">
                <Flex justifyContent="space-between" mb={6}>
                    <HStack alignItems="center" spacing={2}>
                        <Image src={"/kysol.svg"} alt={""} width={8} height={8} rounded={"full"} />
                        <Text fontSize="md" fontWeight="semibold" color="white">
                            kySOL
                        </Text>
                    </HStack>
                </Flex>
                {!isLoading && nodeOperators.length > 0 ? (
                    <Flex direction={"column"}>
                        <TableContainer mt={2}>
                            <Table variant={"info"}>
                                <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Operator ID</Th>
                                        <Th>Staked</Th>
                                        <Th>Cooling</Th>
                                        <Th>Queued</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {nodeOperators
                                        .slice()
                                        .sort((a, b) => Number(b.kySOLStaked) - Number(a.kySOLStaked))
                                        .map((nodeOperator, index) => (
                                            <Tr key={`${index}`} height={"75px"} sx={{ _hover: { backgroundColor: "whiteAlpha.200" }, transition: "background-color 0.3s ease", marginBottom: "20px" }}>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <HStack alignItems="center" spacing={2}>
                                                        <Image src={nodeOperator.image} alt={""} width={6} height={6} />
                                                        <Text>{nodeOperator.name}</Text>
                                                    </HStack>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{`${nodeOperator.id.slice(0, 3)}...${nodeOperator.id.slice(-3)}`}</Text>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{nodeOperator.kySOLStaked}</Text>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{nodeOperator.kySOLCooling}</Text>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{nodeOperator.kySOLQueued}</Text>
                                                </Td>
                                                <Td></Td>
                                            </Tr>
                                        ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                ) : (
                    isLoading ? (
                        <Spinner />
                    ) : (
                        <Text fontSize="md" fontWeight="400" color="#CCCCCC">
                            The realm is quiet, adventurer. There are no DeFi opportunities available at this moment. Stay vigilant and return soon—new paths may open before long!
                        </Text>
                    )
                )}
            </Box>
            <Box bg="#18181b" p={8} className="mint-box">
                <Flex justifyContent="space-between" mb={6}>
                    <HStack alignItems="center" spacing={2}>
                        <Image src={"/kyjto.svg"} alt={""} width={8} height={8} rounded={"full"} />
                        <Text fontSize="md" fontWeight="semibold" color="white">
                            kyJTO
                        </Text>
                    </HStack>
                </Flex>
                {!isLoading && nodeOperators.length > 0 ? (
                    <Flex direction={"column"}>
                        <TableContainer mt={2}>
                            <Table variant={"info"}>
                                <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Operator ID</Th>
                                        <Th>Staked</Th>
                                        <Th>Cooling</Th>
                                        <Th>Queued</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {nodeOperators
                                        .slice()
                                        .sort((a, b) => Number(b.kyJTOStaked) - Number(a.kyJTOStaked))
                                        .map((nodeOperator, index) => (
                                            <Tr key={`${index}`} height={"75px"} sx={{ _hover: { backgroundColor: "whiteAlpha.200" }, transition: "background-color 0.3s ease", marginBottom: "20px" }}>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <HStack alignItems="center" spacing={2}>
                                                        <Image src={nodeOperator.image} alt={""} width={6} height={6} />
                                                        <Text>{nodeOperator.name}</Text>
                                                    </HStack>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{`${nodeOperator.id.slice(0, 3)}...${nodeOperator.id.slice(-3)}`}</Text>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{nodeOperator.kyJTOStaked}</Text>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{nodeOperator.kyJTOCooling}</Text>
                                                </Td>
                                                <Td textColor={"white"} verticalAlign="middle">
                                                    <Text>{nodeOperator.kyJTOQueued}</Text>
                                                </Td>
                                                <Td></Td>
                                            </Tr>
                                        ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>
                ) : (
                    isLoading ? (
                        <Spinner />
                    ) : (
                        <Text fontSize="md" fontWeight="400" color="#CCCCCC">
                            The realm is quiet, adventurer. There are no DeFi opportunities available at this moment. Stay vigilant and return soon—new paths may open before long!
                        </Text>
                    )
                )}
            </Box>
        </>
    )
};