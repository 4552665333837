/* Opportunities */
export interface Opportunities {
    opportunities: Opportunity[];
}

export interface Opportunity {
    protocol: string;
    network: string;
    multiplier: number;
    tvl: number;
    link: string;
}

/* Quests */
export interface Quest {
    id: number;
    title: string;
    levels: {
        id: number;
        title: string;
        description: string;
        rewards: XpReward[];
    }[];
    partners: Partner[];
    startDate?: Date;
    endDate?: Date;
    independentLevels?: boolean
}

export interface XpReward {
    type: XpType,
    amount: number
}

export enum XpType {
    Loyalty = "loyalty",
    Defense = "defense",
    Attack = "attack"
}

export interface Partner {
    name: string;
    image: string;
    url: string;
}

/* Rank */

export interface Rank {
    rank: RankLevel;
    xp: number;
    loyalty: number;
    defense: number;
    attack: number;
    claims: Claim[];
}

export interface RankLevel {
    id: number;
    title: string;
    description: string;
    image: string;
    minXp: number;
    maxXp: number;
    requirements: XpReward[];
}

export interface Claim {
    key: number;
    date: Date;
    level?: number;
}

export interface KyrosError {
    error: string;
}

export type LeaderboardResponse = {
    ranks: Leaderboard[];
    xp: Record<number, number>;
    date: string;
}

export type Leaderboard = {
    rank: RankLevel;
    count: number;
}

/* LST Balances */

export interface LstBalance {
    pubkey: string;
    balance: number;
    metadata: LstMetadata | null;
    price: number;
    value: number;
}

export interface LstMetadata {
    mint: string;
    symbol: string;
    imageUri: string;
    name: string;
    fetchedAt: number;
    decimals: number;
}

export interface LstAvailable {
    available: number;
    lst: LstBalance;
}

/* DeFi */

export interface DeFiOpportunities {
    id: string;
    asset: string;
    assetLogo: string;
    protocol: string;
    protocolLogo: string;
    protocolUrl: string;
    multiplier: number;
    tvl: number;
    apy: { name: string | null, apy: number }[] | null;
    chain: "solana" | "eclipse";
    pointsKey: string | null;
}

/* Points */

export interface Points {
    wallet: string;
    rank: number;
    points: number;
    holdingPoints: number;
    jtoHoldingPoints: number;
    kaminoPoints: number;
    liquidityPoints: number;
    defiPoints: number;
    totalUsers: number;
    referralPoints: number;
}

/* APY */

export interface Apy {
    sol: number;
    jto: number;
    estimatedSol: number;
    estimatedJto: number;
}

/* Ncn */

export interface Ncn {
    id: string;
    name: string;
    image: string;
    description: string;
    url: string;
    live: boolean;
    type: string;
    rewards: {
        name: string;
        symbol: string;
        image: string;
    }[];
}

/* Points Leaderboard */

export interface PointsLeaderboard {
    user: string;
    totalPoints: number;
    holdingPoints: number;
    jtoHoldingPoints: number;
    liquidityPoints: number;
    defiPoints: number;
    referralPoints: number;
}

/* Referrals */

export interface Referral {
    wallet: string;
    code: string;
    referrer: string | null;
    referrals: string[];
    createdAt: Date;
    updatedAt: Date;
}
