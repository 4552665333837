import { Container, Flex, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useErrorToast } from "../helpers/toast";
import { usePointsLeaderboard } from "../hooks/usePointsLeaderboard";
import { LeaderboardTable } from "../components/LeaderboardTable";
import { usePoints } from "../hooks/usePoints";
import { Warchest } from "../components";
import { useWallet } from "@solana/wallet-adapter-react";

export const LeaderboardPage = () => {
  const { points, fetchPoints } = usePoints();
  const { pointsLeaderboard, isLoading, error, fetchPointsLeaderboard } = usePointsLeaderboard();
  const { errorToast } = useErrorToast();
  const { publicKey } = useWallet();
  
  useEffect(() => {
    if (error) {
      errorToast(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (publicKey) {
      fetchPoints(publicKey);
    }
    fetchPointsLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container maxW="1200px" flex="1" py={8}>
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Leaderboard
              </Text>
            </Flex>
            {points && <Warchest points={points} showLeaderboard={false} />}
            <LeaderboardTable pointsLeaderboard={pointsLeaderboard} userRank={points?.rank} isLoading={isLoading} />
          </VStack>
        </Container>
      </Flex>
    </>
  );
};
