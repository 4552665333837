import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { PointsLeaderboard } from '../rest/kairos/types';

export const usePointsLeaderboard = () => {
    const { pointsApiUrl } = useContext(AppConfigContext);
    const [pointsLeaderboard, setPointsLeaderboard] = useState<PointsLeaderboard[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchPointsLeaderboard = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${pointsApiUrl}/leaderboard`);
            const data = await response.json();
            if (data) {
                const pointsLeaderboardData: PointsLeaderboard[] = data.leaderboard;
                setPointsLeaderboard(pointsLeaderboardData);
            }
        } catch (err) {
            console.error("Error fetching Points Leaderboard:", err);
            setError("Error fetching Points Leaderboard");
        } finally {
            setIsLoading(false);
        }
    };

    return { pointsLeaderboard, isLoading, error, fetchPointsLeaderboard };
};
