import { useState } from 'react';
import { PublicKey } from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';
import { Idl, Program } from "@coral-xyz/anchor";
import * as jitoIdl from "../idl/jito_vault_v3.json";

export const useExchangeRate = () => {
  const { connection } = useConnection();

  const [rate, setRate] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchRate = async (vaultAddress: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const program = new Program(jitoIdl as Idl, { connection });

      // @ts-ignore
      const data = await program.account.vault.fetch(new PublicKey(vaultAddress));
      const rate = data.vrtSupply / data.tokensDeposited;

      setRate(rate);
    } catch (error) {
      setError("Error fetching tokens deposited.")
    } finally {
      setIsLoading(false);
    }
  };

  return { rate, isLoading, error, fetchRate };
};
