function roundDown(value: number, fractionDigits: number): number {
  const factor = Math.pow(10, fractionDigits);
  return Math.floor(value * factor) / factor;
}

export const solAmountFormatter = {
  format: (value: number) => {
    const roundedValue = roundDown(value, 6);
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 6,
    }).format(roundedValue);
  }
};

export const usdAmountFormatter = {
  format: (value: number) => {
    const roundedValue = roundDown(value, 0);
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(roundedValue);
  }
};

export const pointsFormatter = {
  format: (value: number) => {
    const roundedValue = roundDown(value, 0);
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(roundedValue);
  }
};