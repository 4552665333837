import { Box, Flex, Table, Th, Tr, Text, TableContainer, Tbody, Td, Thead, Image, Link, HStack, VStack, Badge } from "@chakra-ui/react";
import { Ncn } from "../../rest/kairos/types";
import mixpanel from 'mixpanel-browser';

interface NcnTableProps {
    ncns: Ncn[];
}

export const NcnTable = ({ ncns }: NcnTableProps) => {
    const handleRowClick = (ncn: Ncn) => {
        mixpanel.track('NCN Clicked', {
            id: ncn.id,
            name: ncn.name,
        });
    };

    return (
        <Box bg="#18181b" p={8} className="mint-box" maxW="520px">
            <Flex justifyContent="space-between" mb={6}>
                <Text fontSize="md" fontWeight="semibold" color="white">
                    NCNs
                </Text>
            </Flex>
            {ncns.length > 0 ? (
                <>
                <Flex direction={"column"}>
                    <TableContainer mt={2}>
                        <Table variant={"info"}>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Status</Th>
                                    <Th display={{ base: "none", md: "table-cell" }}>Type</Th>
                                    <Th display={{ base: "none", md: "table-cell" }}>Rewards</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ncns.map((ncn, index) => (
                                    <Link
                                        href={ncn.url}
                                        isExternal
                                        _hover={{ textDecoration: 'none' }}
                                        key={`${index}`}
                                        display={"contents"}
                                        onClick={() => handleRowClick(ncn)}
                                    >
                                        <Tr key={`${index}`} height={"75px"} sx={{ _hover: { backgroundColor: "whiteAlpha.200" }, transition: "background-color 0.3s ease", marginBottom: "20px" }}>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                <VStack alignItems={"flex-start"} spacing={2}>
                                                    <HStack>
                                                        <Image src={ncn.image} alt={ncn.name} width={5} height={5} borderRadius="full" />
                                                        <Text>{ncn.name}</Text>
                                                    </HStack>
                                                </VStack>
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                <HStack>
                                                    <Badge colorScheme={ncn.live ? "green" : "gray"} borderRadius="full" px={2} py={1} fontSize="sm" display="flex" alignItems="center" height="auto">
                                                        {ncn.live ? "Live" : "Soon"}
                                                    </Badge>
                                                </HStack>
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle" display={{ base: "none", md: "table-cell" }}>
                                                <Text>{ncn.type}</Text>
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle" display={{ base: "none", md: "table-cell" }}>
                                                {ncn.rewards.map((reward, index) => (
                                                    <HStack key={`${index}`}>
                                                        <Image src={reward.image} alt={reward.name} width={5} height={5} borderRadius="full" />
                                                    </HStack>
                                                ))}
                                            </Td>
                                            <Td width={"0px"}></Td> {/* The table won't display the last column without this */}
                                        </Tr>
                                    </Link>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
                </>
            ) : (
                <Text fontSize="md" fontWeight="400" color="#CCCCCC">
                    The realm is quiet, adventurer. There are no NCNs where your funds are restaked at the moment.
                </Text>
            )}
        </Box>
    )
};