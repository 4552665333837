import { Box, Flex, Table, Th, Tr, Text, TableContainer, Tbody, Td, Thead, Spinner, HStack, Link, Spacer, VStack, Divider } from "@chakra-ui/react";
import { PointsLeaderboard } from "../../rest/kairos/types";
import { pointsFormatter } from "../../helpers/formatter";
import { useState } from "react";
import { ThemeColor } from "../../theme/palette";

interface LeaderboardTableProps {
    pointsLeaderboard: PointsLeaderboard[];
    userRank: number | undefined;
    isLoading: boolean;
}

export const LeaderboardTable = ({ pointsLeaderboard, isLoading, userRank }: LeaderboardTableProps) => {
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(pointsLeaderboard.length / 10);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    }

    const leftPage = () => {
        if (page === 1 || page === 2) {
            return [];
        } else {
            return [page - 1];
        }
    }

    const rightPage = () => {
        if (page === 1) {
            return [page + 1, page + 2];
        } else if (page === 2) {
            return [page + 1];
        } else if (page === totalPages || page === totalPages - 1) {
            return [];
        } else {
            return [page + 1];
        }
    }

    const leftSpacer = () => {
        if (page >= 4) {
            return true;
        } else {
            return false;
        }
    }

    const rightSpacer = () => {
        if (page <= totalPages - 3) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Box bg="#18181b" p={8} className="mint-box">
                {!isLoading && pointsLeaderboard.length > 0 ? (
                    <>
                        <Flex direction={"column"} display={{ base: "none", md: "block" }}>
                            <TableContainer mt={2}>
                                <Table variant={"info"}>
                                    <Thead>
                                        <Tr>
                                            <Th>Rank</Th>
                                            <Th>User</Th>
                                            <Th>kySOL</Th>
                                            <Th>kyJTO</Th>
                                            <Th>Liquidity & DeFi</Th>
                                            <Th>Referrals</Th>
                                            <Th>Total</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {pointsLeaderboard
                                            .slice((page - 1) * 10, page * 10)
                                            .map((rank, index) => (
                                                <Tr key={`${(page * 10) - 10 + index}`} height={"75px"} >
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        <Text>#{(page * 10) - 10 + 1 + index}</Text>
                                                    </Td>
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        {userRank && ((page * 10) - 10 + 1 + index) === userRank ? (
                                                            <Text>You ✨</Text>
                                                        ) : (
                                                            <Text><Link href={`https://solscan.io/account/${rank.user}`} isExternal>{`${rank.user.slice(0, 6)}...${rank.user.slice(-6)}`}</Link></Text>
                                                        )}
                                                    </Td>
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        <Text>{pointsFormatter.format(rank.holdingPoints)}</Text>
                                                    </Td>
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        <Text>{pointsFormatter.format(rank.jtoHoldingPoints)}</Text>
                                                    </Td>
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        <Text>{pointsFormatter.format(rank.liquidityPoints + rank.defiPoints)}</Text>
                                                    </Td>
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        <Text>{pointsFormatter.format(rank.referralPoints)}</Text>
                                                    </Td>
                                                    <Td textColor={"white"} verticalAlign="middle">
                                                        <Text>{pointsFormatter.format(rank.totalPoints)}</Text>
                                                    </Td>
                                                    <Td></Td>
                                                </Tr>
                                            ))}
                                    </Tbody>
                                </Table>
                                <HStack mt={2}>
                                    <Text fontSize="xs" fontWeight="400" color="#CCCCCC">{pointsLeaderboard.length} villagers</Text>
                                    <Spacer />
                                    <HStack>
                                        <Link onClick={() => handlePageChange(1)} color={page === 1 ? ThemeColor.KAIROS : "white"}>1</Link>
                                        {leftSpacer() && <Text>...</Text>}
                                        {leftPage().map((page) => (
                                            <Link key={page} onClick={() => handlePageChange(page)}>{page}</Link>
                                        ))}
                                        {page !== 1 && page !== totalPages && (
                                            <Link onClick={() => handlePageChange(page)} color={ThemeColor.KAIROS}>{page}</Link>
                                        )}
                                        {rightPage().map((page) => (
                                            <Link key={page} onClick={() => handlePageChange(page)}>{page}</Link>
                                        ))}
                                        {rightSpacer() && <Text>...</Text>}
                                        <Link onClick={() => handlePageChange(totalPages)} color={page === totalPages ? ThemeColor.KAIROS : "white"}>{totalPages}</Link>
                                    </HStack>
                                </HStack>
                            </TableContainer>
                        </Flex>
                        <Flex direction={"column"} display={{ base: "block", md: "none" }}>
                            {pointsLeaderboard
                                .slice((page - 1) * 10, page * 10)
                                .map((rank, index) => (
                                    <>
                                    <VStack key={index} spacing={4} p={2}>
                                        <HStack justifyContent="flex-start">
                                            <Text fontWeight="bold">#{(page * 10) - 10 + 1 + index}</Text>
                                            {userRank && ((page * 10) - 10 + 1 + index) === userRank ? (
                                                <Text>You ✨</Text>
                                            ) : (
                                                <Text><Link href={`https://solscan.io/account/${rank.user}`} isExternal>{`${rank.user.slice(0, 6)}...${rank.user.slice(-6)}`}</Link></Text>
                                            )}
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <VStack>
                                                <Text fontSize="xs" fontWeight="400" color="#CCCCCC">kySOL</Text>
                                                <Text>{pointsFormatter.format(rank.holdingPoints)}</Text>
                                            </VStack>
                                            <Spacer />
                                            <VStack>
                                                <Text fontSize="xs" fontWeight="400" color="#CCCCCC">kyJTO</Text>
                                                <Text>{pointsFormatter.format(rank.jtoHoldingPoints)}</Text>
                                            </VStack>
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <VStack>
                                                <Text fontSize="xs" fontWeight="400" color="#CCCCCC">Liquidity & DeFi</Text>
                                                <Text>{pointsFormatter.format(rank.liquidityPoints + rank.defiPoints)}</Text>
                                            </VStack>
                                            <Spacer />
                                            <VStack>
                                                <Text fontSize="xs" fontWeight="400" color="#CCCCCC">Referrals</Text>
                                                <Text fontWeight="bold">{pointsFormatter.format(rank.referralPoints)}</Text>
                                            </VStack>
                                        </HStack>
                                        <HStack justifyContent="space-between" width="100%">
                                            <VStack>
                                                <Text fontSize="xs" fontWeight="400" color="#CCCCCC">Total</Text>
                                                <Text fontWeight="bold">{pointsFormatter.format(rank.totalPoints)}</Text>
                                            </VStack>
                                        </HStack>
                                    </VStack>
                                    <Divider mt={2} mb={2} />
                                    </>
                                ))}

                            <HStack mt={4}>
                                <Text fontSize="xs" fontWeight="400" color="#CCCCCC">{pointsLeaderboard.length} villagers</Text>
                                <Spacer />
                                <HStack>
                                    <Link onClick={() => handlePageChange(1)} color={page === 1 ? ThemeColor.KAIROS : "white"}>1</Link>
                                    {leftSpacer() && <Text>...</Text>}
                                    {leftPage().map((page) => (
                                        <Link key={page} onClick={() => handlePageChange(page)}>{page}</Link>
                                    ))}
                                    {page !== 1 && page !== totalPages && (
                                        <Link onClick={() => handlePageChange(page)} color={ThemeColor.KAIROS}>{page}</Link>
                                    )}
                                    {rightPage().map((page) => (
                                        <Link key={page} onClick={() => handlePageChange(page)}>{page}</Link>
                                    ))}
                                    {rightSpacer() && <Text>...</Text>}
                                    <Link onClick={() => handlePageChange(totalPages)} color={page === totalPages ? ThemeColor.KAIROS : "white"}>{totalPages}</Link>
                                </HStack>
                            </HStack>
                        </Flex>
                    </>
                ) : (
                    isLoading ? (
                        <Spinner />
                    ) : (
                        <Text fontSize="md" fontWeight="400" color="#CCCCCC">
                            The realm is quiet, adventurer. The leaderboard is empty at this moment. Stay vigilant and return soon—new paths may open before long!
                        </Text>
                    )
                )}
            </Box>
        </>
    )
};