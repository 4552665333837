import { Container, Flex, Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { DashboardTable } from "../components/DashboardTable";
import { useNodeOperators } from "../hooks/useNodeOperators";
import { useErrorToast } from "../helpers/toast";

export const DashboardPage = () => {
  const { publicKey } = useWallet();
  const { nodeOperators, isLoading, error, fetchNodeOperators } = useNodeOperators();
  const { errorToast } = useErrorToast();

  useEffect(() => {
    if (error) {
      errorToast(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    fetchNodeOperators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container maxW="900px" flex="1" py={8}>
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Node Operators Dashboard
              </Text>
            </Flex>

            <DashboardTable nodeOperators={nodeOperators} isLoading={isLoading} />
          </VStack>
        </Container>
      </Flex>
    </>
  );
};
