import { Flex, Text } from "@chakra-ui/react";
import { ThemeColor } from "../../theme/palette";
import { getAlphaColor } from "../../theme/utils";
import { ReactNode, useEffect, useState } from "react";

export const Notice = () => {
    const [text, setText] = useState<ReactNode | null>(null);

    useEffect(() => {
        setText(<Text textAlign={"center"}>NEW: Share your <a href="https://app.kyros.fi/village" rel="noopener noreferrer">referral code</a> to your friends and enjoy <b>10%</b> of their points!</Text>)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Flex
            color={ThemeColor.KAIROS}
            bg={getAlphaColor(ThemeColor.KAIROS as string, 0.1)}
            px={"1rem"}
            py={"1.5rem"}
            alignItems={"center"}
            textAlign={"center"}
            sx={{
                a: {
                    color: getAlphaColor(ThemeColor.KAIROS as string, 1),
                    fontWeight: "bold",
                },
                "a:hover": {
                    color: getAlphaColor(ThemeColor.KAIROS as string, 0.6),
                },
            }}
            flexDirection={"column"}
        >
            {text}
        </Flex>
    );
};