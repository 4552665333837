import { Box, Flex, Text, VStack, HStack, Button, Image, Input, InputGroup, Spinner, Center, Checkbox } from "@chakra-ui/react";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useErrorToast, useSuccessToast } from "../../helpers/toast";
import { useContext, useEffect, useState } from "react";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReferrals } from "../../hooks/useReferrals";
import { useWallet } from "@solana/wallet-adapter-react";
import base58 from "bs58";
import { Connection, PublicKey } from "@solana/web3.js";
import { Transaction } from "@solana/web3.js";
import { SystemProgram } from "@solana/web3.js";
import { AppConfigContext } from "../../context";
import { useLocation } from "react-router-dom";
import { usdAmountFormatter } from '../../helpers/formatter';
import mixpanel from "mixpanel-browser";

interface Props {
    referralPoints: number | null;
}

export const Referral = ({ referralPoints }: Props) => {
    const { rpcUrl } = useContext(AppConfigContext)
    const { successToast } = useSuccessToast();
    const { referralProfile, fetchReferrals, challenge, fetchChallenge, error, verifyChallenge, challengeVerified } = useReferrals();
    const { publicKey, signMessage, signTransaction } = useWallet();
    const { errorToast } = useErrorToast();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialReferralCode = queryParams.get('ref') || "";

    const [referralCode, setReferralCode] = useState<string>(initialReferralCode);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [walletError, setWalletError] = useState<string | null>(null);
    const [isLedger, setIsLedger] = useState<boolean>(false);

    const handleCopy = () => {
        if (referralProfile) {
            mixpanel.track("Copy Referral Code", {
                referral_code: referralProfile.code
            });
            navigator.clipboard.writeText(referralProfile.code);
            successToast("Copied to clipboard");
        }
    }

    const handleExternalLink = () => {
        if (referralProfile) {
            mixpanel.track("Copy Referral Link", {
                referral_code: referralProfile.code
            });
            navigator.clipboard.writeText("https://boost.kyros.fi/?ref=" + referralProfile.code);
            successToast("Copied to clipboard");
        }
    }

    const handleTwitter = () => {
        if (referralProfile) {
            mixpanel.track("Copy Referral Twitter", {
                referral_code: referralProfile.code
            });
            window.open(`https://x.com/intent/post?text=Restake%20your%20SOL%20and%20your%20JTO%20with%20%40kyrosfi%20and%20get%2010%25%20of%20your%20friends'%20points!%20Use%20my%20referral%20code!%20https://boost.kyros.fi/?ref=${referralProfile.code}`, "_blank");
        }
    }

    const handleReferralSubmit = () => {
        fetchChallenge(publicKey, referralCode);
        setIsLoading(true);
    }

    useEffect(() => {
        fetchReferrals(publicKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey]);

    useEffect(() => {
        const handleChallenge = async () => {
            if (challenge && publicKey) {
                try {
                    if (isLedger) {
                        if (!signTransaction) {
                            throw new Error("Sign transaction not found");
                        }
                        const connection = new Connection(rpcUrl);
                        const transaction = new Transaction();
                        // Self-transfer
                        transaction.add(
                            SystemProgram.transfer({
                                fromPubkey: publicKey,
                                toPubkey: publicKey,
                                lamports: 0,
                            })
                        );

                        transaction.add({
                            programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
                            keys: [],
                            data: Buffer.from(challenge, 'utf-8'),
                        });

                        transaction.feePayer = publicKey;
                        transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

                        const signedTx = await signTransaction(transaction);
                        verifyChallenge(publicKey, base58.encode(signedTx.serialize()), isLedger);
                    } else {
                        if (!signMessage) {
                            throw new Error("Sign message not found");
                        }
                        const encodedMessage = new TextEncoder().encode(challenge);
                        const signature = await signMessage(encodedMessage);
                        verifyChallenge(publicKey, base58.encode(signature), isLedger);
                    }
                } catch (err) {
                    setWalletError("Error signing message: " + err);
                }
            } else {
                setIsLoading(false);
            }
        }
        handleChallenge();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challenge, isLedger, rpcUrl]);

    useEffect(() => {
        if (error) {
            errorToast(error);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (walletError) {
            errorToast(walletError);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletError]);

    useEffect(() => {
        if (challengeVerified) {
            successToast("Successfully joined the alliance!");
            setIsLoading(false);
            fetchReferrals(publicKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeVerified, publicKey]);

    return (
        <Box bg="#18181b" p={8} className="mint-box" maxW="100%">
            <Flex justifyContent="space-between" mb={6}>
                <Text
                    fontWeight="bold"
                    fontSize="xl"
                    color="white"
                    lineHeight="1.2"
                    mr={2}
                >
                    The Alliance 🤝
                </Text>
            </Flex>
            <Text fontSize="md" color="whiteAlpha.700">Refer your friends to join your alliance and get 10% of their points.</Text>

            {!referralProfile && <>
                <Center p={4}>
                    <Spinner />
                </Center>
            </>
            }

            {referralProfile && !referralProfile.referrer && <>
                <Box
                    p={4}
                    borderRadius="8px"
                    backgroundColor="whiteAlpha.100"
                    mt={4}
                >
                    <Flex alignItems="center" gap={4} p={2} justifyContent="space-between">
                        <VStack align="flex-start" spacing={4}>
                            <Text fontSize="md" fontWeight="semibold" color="white">
                                Join an alliance, earn a boost for you and your friends.
                            </Text>
                            <HStack width="100%">
                                <InputGroup size="md">
                                    <Input
                                        pr="4.5rem"
                                        type="text"
                                        placeholder="Enter referral code"
                                        value={referralCode}
                                        onChange={(e) => setReferralCode(e.target.value)}
                                    />
                                </InputGroup>
                                <Button backgroundColor="whiteAlpha.100" _hover={{ backgroundColor: "whiteAlpha.200" }} onClick={handleReferralSubmit} disabled={!referralCode || referralCode.length !== 6 || isLoading} isLoading={isLoading}>Submit</Button>
                            </HStack>
                            <HStack spacing={2}>
                                <Checkbox size="md" onChange={(e) => setIsLedger(e.target.checked)} />
                                <Text fontSize="sm" color="whiteAlpha.700">
                                    Using Ledger?
                                </Text>
                            </HStack>
                        </VStack>
                        <Image
                            src="/kyros/alliance.png"
                            boxSize="100px"
                            display={{ base: "none", md: "block" }}
                        />
                    </Flex>
                </Box>
            </>
            }

            {referralProfile && <>
                <Flex justifyContent="space-between" mt={4}>
                    <HStack align="flex-start" spacing={4} width="100%" alignItems="stretch">
                        <Box
                            width="50%"
                            display="flex"
                            alignItems="center"
                            gap={4}
                            p={4}
                            borderRadius="md"
                            backgroundColor="whiteAlpha.100"
                            flex="1"
                        >
                            <VStack spacing={2} align="flex-start" justify="space-between" height="100%">
                                <Text fontSize="md" color="white" alignSelf="flex-start">
                                    Referral Code
                                </Text>
                                <VStack spacing={2} align="flex-start" flex="1" justify="center">
                                    <HStack spacing={0} >
                                        <Text fontSize="xl" fontWeight="bold" color="white">
                                            {referralProfile?.code}
                                        </Text>
                                        <Button variant="ghost" size="sm" onClick={handleCopy} width="20px" ml={2}>
                                            <CopyIcon />
                                        </Button>
                                        <Button variant="ghost" size="sm" onClick={handleExternalLink} width="20px">
                                            <ExternalLinkIcon />
                                        </Button>
                                        <Button variant="ghost" size="sm" onClick={handleTwitter} width="20px">
                                            <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: "18px" }} />
                                        </Button>
                                    </HStack>
                                    {referralProfile?.referrer && <Text fontSize="xs" color="whiteAlpha.700" fontStyle="italic">Referred by <b>{referralProfile.referrer.slice(0, 5)}...{referralProfile.referrer.slice(-3)}</b></Text>}
                                </VStack>
                            </VStack>
                        </Box>
                        <Box
                            width="50%"
                            display="flex"
                            alignItems="center"
                            gap={4}
                            p={4}
                            borderRadius="md"
                            backgroundColor="whiteAlpha.100"
                            flex="1"
                        >
                            <VStack spacing={2} align="flex-start">
                                <Text fontSize="md" color="white" alignSelf="flex-start">
                                    Referrals
                                </Text>
                                <Text fontSize="xl" fontWeight="bold" color="white" align="center">
                                    {referralProfile?.referrals.length}
                                </Text>
                                {!referralPoints && referralProfile?.referrals.length === 0 && <Text fontSize="xs" color="whiteAlpha.700" fontStyle="italic">Refer your friends for extra points!</Text>}
                                {!referralPoints && referralProfile?.referrals.length > 0 && <Text fontSize="xs" color="whiteAlpha.700" fontStyle="italic">Points are refreshed every 24 hours, come back soon to see your points.</Text>}
                                {referralPoints ? <Text fontSize="xs" color="whiteAlpha.700" fontStyle="italic">You have earned <b>{usdAmountFormatter.format(referralPoints)}</b> points from referrals.</Text> : <Text fontSize="sm" color="whiteAlpha.700"></Text>}
                            </VStack>
                        </Box>
                    </HStack>
                </Flex>
            </>
            }
        </Box>
    );
}
