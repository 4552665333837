import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { PublicKey } from '@solana/web3.js';
import { Points } from '../rest/kairos/types';

export const usePoints = () => {
    const { pointsApiUrl } = useContext(AppConfigContext);
    const [points, setPoints] = useState<Points | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchPoints = async (wallet: PublicKey | null) => {
        if (!wallet) {
            setPoints(null);
            setIsLoading(false);
            setError("Wallet not connected");
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${pointsApiUrl}/score?wallet=${wallet.toBase58()}`);
            const data = await response.json();
            if (data) {
                const pointsData: Points = data;
                setPoints(pointsData);
            }
        } catch (err) {
            console.error("Error fetching Points:", err);
            setPoints(null);
            setError("Error fetching Points");
        } finally {
            setIsLoading(false);
        }
    };

    return { points, isLoading, error, fetchPoints };
};
