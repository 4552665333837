import { useState, useContext } from 'react';
import { AppConfigContext } from '../context';
import { Ncn } from '../rest/kairos/types';

export const useNcns = () => {
    const { apiUrl } = useContext(AppConfigContext);
    const [solNcns, setSolNcns] = useState<Ncn[] | null>(null);
    const [jtoNcns, setJtoNcns] = useState<Ncn[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchNcns = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/kyros/ncns`);
            const data = await response.json();
            if (data) {
                const solNcnsData: Ncn[] = data.sol;
                const jtoNcnsData: Ncn[] = data.jto;
                setSolNcns(solNcnsData);
                setJtoNcns(jtoNcnsData);
            }
        } catch (err) {
            console.error("Error fetching NCNs:", err);
            setSolNcns(null);
            setJtoNcns(null);
            setError("Error fetching NCNs");
        } finally {
            setIsLoading(false);
        }
    };

    return { solNcns, jtoNcns, isLoading, error, fetchNcns };
};
